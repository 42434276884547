

@media (max-width: 989px) {
    .titles{
        font-size: 0.45rem !important;
}
.res-button{
    padding: 0.5rem !important;
    margin: 0!important;
    display: flex !important;
    align-items: center !important;
    /* justify-content: end !important; */
}

.res-button .ant-btn-icon{
    /* padding: 0.5rem !important; */
    margin: 0!important;
    display: flex !important;
    align-items: center !important;
    /* justify-content: end !important; */
}
}

