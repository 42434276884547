/*----------------genealogy-scroll----------*/

.genealogy-scroll::-webkit-scrollbar {
    width: 5px;
    height: 8px;
}
.genealogy-scroll::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #e4e4e4;
}
.genealogy-scroll::-webkit-scrollbar-thumb {
    background: #212121;
    border-radius: 10px;
    transition: 0.5s;
}
.genealogy-scroll::-webkit-scrollbar-thumb:hover {
    background: #d5b14c;
    transition: 0.5s;
}


/*----------------genealogy-tree----------*/
.genealogy-body{
    white-space: nowrap;
    overflow-y: hidden;
    padding: 5px;
    /* min-height: 90%; */
    /* min-width: 90%; */
    padding-top: 0px;
    cursor: grab;
    position: relative;
    /* overflow: auto; */
    transition: all 0.3s ease-in-out;


}
.ng{
    width: 100%;
    height: 100vh;
    background-size: 40px 40px;
    background-image: linear-gradient(to right, #cccccc49 1px, transparent 1px), linear-gradient(to bottom, #cccccc38 1px, transparent 1px);
    transition: all 0.3s ease-in-out;
}
a:hover{
    text-decoration: none;
    transition: all 0.3s ease-in-out;

}
.dragging {
    cursor: grabbing;
  }
.genealogy-tree{
    width: fit-content;
    height:fit-content;
    transition: all 0.3s ease-in-out;

}
.genealogy-tree ul {
    padding-top: 20px; 
    position: relative;
    padding-left: 0px;
    display: flex;
    /* flex-wrap: nowrap !important; */
    /* width: 100vw; */

    transition: all 0.3s ease-in-out;

}
#root{
    /* width: 100%; */
    display: flex;

}
.genealogy-tree li {
    float: left; text-align: center;
    list-style-type: none;
    position: relative;
    padding: 20px 5px 0 5px;
    direction: ltr !important;
    transition: all 0.3s ease-in-out;

}
.genealogy-tree li::before, .genealogy-tree li::after{
    content: '';
    position: absolute; 
  top: 0; 
  right: 50%;
    border-top: 2px solid #ccc;
    width: 50%; 
  height: 18px;
  transition: all 0.3s ease-in-out;

}
.genealogy-tree li::after{
    right: auto; left: 50%;
    border-left: 2px solid #ccc;
    transition: all 0.3s ease-in-out;

}
.genealogy-tree li:only-child::after, .genealogy-tree li:only-child::before {
    display: none;
}
.genealogy-tree li:only-child{ 
    padding-top: 0;
}
.genealogy-tree li:first-child::before, .genealogy-tree li:last-child::after{
    border: 0 none;
}
.genealogy-tree li:last-child::before{
    border-right: 2px solid #ccc;
    border-radius: 0 5px 0 0;
    -webkit-border-radius: 0 5px 0 0;
    -moz-border-radius: 0 5px 0 0;
}
.genealogy-tree li:first-child::after{
    border-radius: 5px 0 0 0;
    -webkit-border-radius: 5px 0 0 0;
    -moz-border-radius: 5px 0 0 0;
}
.genealogy-tree ul ul::before{
    content: '';
    position: absolute; top: 0; left: 50%;
    border-left: 2px solid #ccc;
    width: 0; height: 20px;
}
.genealogy-tree li a{
    text-decoration: none;
    color: #666;
    font-family: arial, verdana, tahoma;
    font-size: 11px;
    display: inline-block;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
}

.genealogy-tree li a:hover+ul li::after, 
.genealogy-tree li a:hover+ul li::before, 
.genealogy-tree li a:hover+ul::before, 
.genealogy-tree li a:hover+ul ul::before{
    border-color:  #fbba00;
}
.center-div {
    /* position: absolute; */
    /* top: 50%; */
    /* left: 50%; */
    /* transform: translate(-50%, -50%); */
    display: flex;
    justify-content: center;
}
.center-div>div {
    /* position: absolute; */
    /* top: 50%; */
    /* left: 50%; */
    /* transform: translate(-50%, -50%); */
    width: 14rem;

}
/*--------------memeber-card-design----------*/
.member-view-box{
    margin: 1rem;
    padding: 1rem 2rem;
    text-align: center;
    border-radius: 10px;
    position: relative;
    border: 2px solid rgb(173, 173, 173);
    box-shadow: -5px 5px 86px -26px rgba(0,0,0,0.47);
-webkit-box-shadow: -5px 5px 86px -26px rgba(0,0,0,0.47);
-moz-box-shadow: -5px 5px 86px -26px rgba(0,0,0,0.47);
}
.member-image{
    width: auto;
    position: relative;
}
.member-image img{
    width: 3rem;
    height: 3rem;
    border-radius: 6px;
  background-color :#00000000;
  z-index: 1;
}
.design{
    color: rgb(168, 168, 168);
    font-size: medium;
    text-transform: uppercase;

}
hr {
    border: 0;
    clear:both;
    display:block;
    width: 96%;               
    background-color:#FFFF00;
    height: 1px;
  }
  .member-details  h3 {
    font-size: x-large;
    text-transform: capitalize;

  }