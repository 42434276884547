/* .responsive-card{
    width: 70vw;
}
@media (max-width: 989px) {
    .responsive-card{
        width: 60vw;
    }
} */
:where(.css-dev-only-do-not-override-7qdh2f).ant-steps .ant-steps-item-finish .ant-steps-item-icon >.ant-steps-icon {
    color: #F0FDFF;
}
:where(.css-dev-only-do-not-override-7qdh2f).ant-steps .ant-steps-item-finish .ant-steps-item-icon {
    background-color: #5BB7DD;
    border-color: #f0fdff;
}
:where(.css-dev-only-do-not-override-7qdh2f).ant-steps .ant-steps-item-icon{
    background-color: #F0F0F0;
}