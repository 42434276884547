@font-face {
  font-family: 'KABi';
  src: url('../public/font/kabiFontBold.ttf');
}
body{
  margin: 0;
  height: 100vh;

  font-size:100%;
}

*{
  box-sizing: border-box;
  font-family: 'KABi' !important;
}

a,a:visited,a:active,a:focus{
  text-decoration: none;
}


::-webkit-scrollbar {
  height: 0px;
  margin-left: 32px;
  width: 3px;
  

  
}

::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, .2);
  border-radius: 20px;
}

::-webkit-scrollbar-track {
  border-radius: 20px;
  box-shadow: none;
}
:where(.css-dev-only-do-not-override-7qdh2f).ant-btn-primary:disabled, :where(.css-dev-only-do-not-override-7qdh2f).ant-btn-primary.ant-btn-disabled {
  cursor: not-allowed;
  border-color: #d9d9d9;
  color: rgba(0, 0, 0, 0.25) !important;
  background: rgba(0, 0, 0, 0.04) !important;
  box-shadow: none;
}