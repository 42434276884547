:where(.css-dev-only-do-not-override-7qdh2f).ant-layout .ant-layout-sider-zero-width-trigger {
    background-color:#29A0D1 !important ;
  }
.full-name{
  font-size: small;
  line-height: 1.2;
}
.job-title{
  font-size: smaller;
  color: gray;
  line-height: 1;
}



:where(.css-dev-only-do-not-override-mhirdm).ant-layout .ant-layout-sider-children,:where(.css-dev-only-do-not-override-7qdh2f).ant-layout .ant-layout-sider-children {

  overflow-y: scroll;
}
